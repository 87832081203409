import { mapHelper } from "@/utils/common.js";
// 抽奖状态
const luckDrawStatusList = [
  {
    value: null,
    label: "全部",
  },
  {
    value: 1,
    label: "未开始",
  },
  {
    value: 2,
    label: "进行中",
  },
  {
    value: 3,
    label: "已结束",
  },
  {
    value: 0,
    label: "已下架",
  },
];
const { map: luckDrawStatusListMap, setOps: luckDrawStatusListOps } =
  mapHelper.setMap(luckDrawStatusList);
// 奖项等级
const prizeLevelList = [
  {
    value: "一等奖",
    label: "一等奖",
  },
  {
    value: "二等奖",
    label: "二等奖",
  },
  {
    value: "三等奖",
    label: "三等奖",
  },
  {
    value: "四等奖",
    label: "四等奖",
  },
  {
    value: "五等奖",
    label: "五等奖",
  },
  {
    value: "六等奖",
    label: "六等奖",
  },
  {
    value: "七等奖",
    label: "七等奖",
  },
  {
    value: "八等奖",
    label: "八等奖",
  },
  {
    value: "九等奖",
    label: "九等奖",
  },
  {
    value: "十等奖",
    label: "十等奖",
  },
];
const { map: prizeLevelListMap, setOps: prizeLevelListOps } =
  mapHelper.setMap(prizeLevelList);
// 奖品类型
const prizeTypeList = [
  {
    value: "积分",
    label: "积分",
  },
  {
    value: "实物",
    label: "实物",
  },
  {
    value: "优惠券",
    label: "优惠券",
  },
];
const { map: prizeTypeListMap, setOps: prizeTypeListOps } =
  mapHelper.setMap(prizeTypeList);
// 抽奖结果
const raffleResultList = [
  {
    value: null,
    label: "全部",
  },
  {
    value: 1,
    label: "未知",
  },
  {
    value: 2,
    label: "未中奖",
  },
  {
    value: 3,
    label: "已中奖",
  },
];
const { map: raffleResultListMap, setOps: raffleResultListOps } =
  mapHelper.setMap(raffleResultList);
export {
  luckDrawStatusList,
  luckDrawStatusListMap,
  luckDrawStatusListOps,
  prizeLevelList,
  prizeLevelListMap,
  prizeLevelListOps,
  prizeTypeList,
  prizeTypeListMap,
  prizeTypeListOps,
  raffleResultList,
  raffleResultListMap,
  raffleResultListOps,
};
