<template>
  <div class="page">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
    >
      <template #headBtnSlot>
        <v-button text="创建抽奖" @click="createLuckDraw()"></v-button>
      </template>
      <template #searchSlot>
        <v-input
          placeholder="请输入抽奖名称"
          label="抽奖名称"
          v-model="searchParam.lotteryName"
          clearable
        />
        <v-select
          :options="luckDrawStatusList"
          v-model="searchParam.status"
          label="状态"
        />
      </template>
      <template #operateSlot="scope">
        <v-button
          v-if="
            scope.row.status === 1 ||
            scope.row.status === 2 ||
            scope.row.status === 3
          "
          text="编辑"
          type="text"
          @click="toEdit(scope.row)"
        />
        <v-button
          v-if="scope.row.status === 1"
          text="删除"
          type="text"
          @click="toDelete(scope.row)"
        />
        <v-button
          v-if="scope.row.status === 2"
          text="下架"
          type="text"
          @click="lowerShelf(scope.row, 0)"
        />
        <v-button
          v-if="scope.row.status === 0"
          text="上架"
          type="text"
          @click="lowerShelf(scope.row, 1)"
        />
      </template>
    </v-list>
  </div>
</template>

<script>
import {
  luckDrawStatusList,
  luckDrawStatusListMap,
  luckDrawStatusListOps,
  prizeLevelList,
  prizeLevelListMap,
  prizeLevelListOps,
  prizeTypeList,
  prizeTypeListMap,
  prizeTypeListOps,
  raffleResultList,
  raffleResultListMap,
  raffleResultListOps,
} from "./map.js";
import { createImgVNode, createHTMLVNode } from "@/utils/utils.js";
import {
  addOrUpdateInfoUrl,
  getLotteryDetailUrl,
  getLotteryApplyListUrl,
  getLotteryListUrl,
  deleteLotteryByIdUrl,
  updateStatusByIdUrl,
} from "./api.js";
import { Message, MessageBox } from "element-ui";
export default {
  data() {
    return {
      luckDrawStatusList,
      tableUrl: getLotteryListUrl,
      searchParam: {
        lotteryName: "",
        status: null,
      },
      headers: [
        {
          prop: "lotteryName",
          label: "抽奖名称",
        },
        {
          prop: "image",
          label: "抽奖图片",
          formatter: (row, prop) => createImgVNode(this, row, prop),
        },
        {
          prop: "applyPersons",
          label: "报名人数",
          formatter: (row, prop) => {
            let oBtn = `<span>${row.applyPersons}</span> `;
            return createHTMLVNode(this, oBtn, row, prop, {
              cb: (e, row) => {
                this.$router.push({
                  name: "luckDrawApplicantList",
                  query: {
                    id: row.id,
                  },
                });
              },
            });
          },
        },
        {
          prop: "startTime",
          label: "抽奖开始时间",
        },
        {
          prop: "endTime",
          label: "抽奖结束时间",
        },
        {
          prop: "status",
          label: "抽奖状态",
          formatter: (row, prop) => {
            return luckDrawStatusListMap[row.status];
          },
        },
      ],
    };
  },
  mounted() {},
  methods: {
    // 编辑
    toEdit(row) {
      this.$router.push({
        name: "luckDrawForm",
        query: {
          id: row.id,
        },
      });
    },
    // 删除
    toDelete(row) {
      //删除按钮
      MessageBox.confirm("是否删除", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = {
          id: row.id,
        };
        this.$axios
          .post(`${deleteLotteryByIdUrl}`, this.$qs.stringify(params))
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("操作成功！");
              this.$refs.list.search();
            }
          });
      });
    },
    // 下架/上架
    lowerShelf(row, status) {
      let params = {
        id: row.id,
        status: status,
      };
      this.$axios
        .post(`${updateStatusByIdUrl}`, this.$qs.stringify(params))
        .then((res) => {
          if (res.code === 200) {
            this.$message.success(`${status === 0 ? "下架" : "上架"}成功！`);
            this.$refs.list.search();
          }
        });
    },
    // 创建抽奖
    createLuckDraw() {
      this.$router.push({
        name: "luckDrawForm",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  box-sizing: border-box;
  height: 100%;
}
</style>