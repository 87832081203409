// 新增/编辑
const addOrUpdateInfoUrl = `/gateway/hc-neighbour/manage/lottery/addOrUpdateInfo`;
// 详情
const getLotteryDetailUrl = `/gateway/hc-neighbour/manage/lottery/getLotteryDetail`;
// 报名详情列表
const getLotteryApplyListUrl = `/gateway/hc-neighbour/manage/lottery/getLotteryApplyList`;
// 抽奖列表
const getLotteryListUrl = `/gateway/hc-neighbour/manage/lottery/getLotteryList`;
// 删除
const deleteLotteryByIdUrl = `/gateway/hc-neighbour/manage/lottery/deleteLotteryById`;
// 下架/上架
const updateStatusByIdUrl = `/gateway/hc-neighbour/manage/lottery/updateStatusById`;
// 奖品类型
const getPrizeTypeList = `/gateway/blade-system/application/getFunctionSetting`;
export {
  addOrUpdateInfoUrl,
  getLotteryDetailUrl,
  getLotteryApplyListUrl,
  getLotteryListUrl,
  deleteLotteryByIdUrl,
  updateStatusByIdUrl,
  getPrizeTypeList,
};
